<script>
/**
 * Xero Account Info
 * @version 1.0.0
 * @since
 */

import xeroGuard from '@/views/platform/venue/xero/settings/xeroGuard';
import {
  venueGetXeroAccounts,
  venueXeroSetupAccount,
  venueGetXeroAccountSetup,
  venueGetXeroTaxesSetup,
} from '@/api/endpoints/xero';
import EzButton from '@/components/ui/Button/EzButton';
import XeroAccountSettingsForm from '@/views/platform/distributor/xero/setup/XeroAccountSettingsForm';
import { mapActions } from 'vuex';

export default {
  mixins: [xeroGuard],
  components: {
    EzButton,
    XeroAccountSettingsForm,
  },
  props: {
    venueId: {
      type: Number,
    },
  },
  data() {
    return {
      accounts: [],
      taxes: [],
      selectedAccount: '',
      selectedInventoryAccount: '',
      buttonDisabled: true,
      selectedTax: '',
      selectedDeliveryFeeAccount: '',
      selectedOrderDiscountAccount: '',
      selectedTaxAdjustmentAccount: '',
      selectedAmountAdjustmentAccount: '',
      selectedExportModeId: '',
      orderExportModes: [
        { id: 'manual', name: 'Manual' },
        { id: 'product_groups', name: 'Product Group Settings Required' },
        { id: 'auto', name: 'Automatic' },
      ],
    };
  },
  methods: {
    ...mapActions('entities/users', ['initVenue']),
    changeSelectedAccount(item) {
      this.selectedAccount = item.code;
      this.buttonDisabled = false;
    },
    changeSelectedInventoryAccount(item) {
      this.selectedInventoryAccount = item.code;
      this.buttonDisabled = false;
    },
    changeTax(item) {
      this.buttonDisabled = false;
      this.selectedTax = item.taxType;
    },
    changeDeliveryFee(item) {
      this.buttonDisabled = false;
      this.selectedDeliveryFeeAccount = item.code;
    },
    changeDiscount(item) {
      this.buttonDisabled = false;
      this.selectedOrderDiscountAccount = item.code;
    },
    changeTaxAdjustment(item) {
      this.buttonDisabled = false;
      this.selectedTaxAdjustmentAccount = item.code;
    },
    changeAmountAdjustment(item) {
      this.buttonDisabled = false;
      this.selectedAmountAdjustmentAccount = item.code;
    },
    changeExportMode(item) {
      this.buttonDisabled = false;
      this.selectedExportModeId = item.id;
    },
    async saveChanges() {
      const account = {
        accountCode: this.selectedAccount || '',
        venueInventoryAccountCode: this.selectedInventoryAccount || '',
        taxType: this.selectedTax || '',
        deliveryFeeAccountCode: this.selectedDeliveryFeeAccount || '',
        orderDiscountAccountCode: this.selectedOrderDiscountAccount || '',
        taxAdjustmentAccountCode: this.selectedTaxAdjustmentAccount || '',
        amountAdjustmentAccountCode: this.selectedAmountAdjustmentAccount || '',
        accountingInvoicePush: this.selectedExportModeId || '',
      };
      await venueXeroSetupAccount(this.venueId, account);
      this.buttonDisabled = true;
    },
  },
  async created() {
    this.initVenue();
    const {
      data: { data: accounts },
    } = await venueGetXeroAccounts(this.venueId);
    this.accounts = accounts;
    const {
      data: { data: taxes },
    } = await venueGetXeroTaxesSetup(this.venueId);
    this.taxes = taxes;

    const {
      data: { data },
    } = await venueGetXeroAccountSetup(this.venueId);
    if (data) {
      this.selectedAccount = data.accountCode;
      this.selectedInventoryAccount = data.venueInventoryAccountCode;
      this.selectedTax = data.taxType;
      this.selectedDeliveryFeeAccount = data.deliveryFeeAccountCode;
      this.selectedOrderDiscountAccount = data.orderDiscountAccountCode;
      this.selectedTaxAdjustmentAccount = data.taxAdjustmentAccountCode || '';
      this.selectedAmountAdjustmentAccount = data.amountAdjustmentAccountCode || '';
      this.selectedExportModeId = data.accountingInvoicePush || '';
    }
  },
};
</script>
<template>
  <div class="container--small">
    <xero-account-settings-form
      @changeAccount="changeSelectedAccount"
      @changeTax="changeTax"
      @changeDeliveryFee="changeDeliveryFee"
      @changeDiscount="changeDiscount"
      @changeTaxAdjustment="changeTaxAdjustment"
      @changeAmountAdjustment="changeAmountAdjustment"
      @changeExportMode="changeExportMode"
      @changeInventoryAccount="changeSelectedInventoryAccount"
      :accounts="accounts"
      :orderExportModes="orderExportModes"
      :selected-account="selectedAccount"
      :selected-inventory-account="selectedInventoryAccount"
      :selected-tax="selectedTax"
      :selected-delivery-fee-account="selectedDeliveryFeeAccount"
      :selected-tax-adjustment="selectedTaxAdjustmentAccount"
      :selected-amount-adjustment="selectedAmountAdjustmentAccount"
      :selected-order-discount-account="selectedOrderDiscountAccount"
      :selected-export-mode-id="selectedExportModeId"
      :taxes="taxes"
    />
    <ez-button @click="saveChanges" :disabled="buttonDisabled" class="mt-24">
      {{ $t('xero.settings.account.buttonText') }}
    </ez-button>
  </div>
</template>
<style lang="scss" scoped></style>
