<script>
/**
 * Xero venues
 * @version 1.0.0
 * @since
 */

import xeroGuard from '@/views/platform/venue/xero/settings/xeroGuard';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch/index';
import EzTable from '@/components/ui/Table/EzTable';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo/index';
import {
  venueGetConnectedDistributors,
  venueGetContacts,
  venueXeroAddConnections,
  venueFetchTrackingCategories,
  venueGetXeroAccounts,
  venueXeroConnections,
  venueGetXeroTaxesSetup,
} from '@/api/endpoints/xero';
import flash from '@/components/ui/FlashMessage';
import EmptyState from '@/views/common/empty-state/EmptyState';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import EzLoader from '@/components/ui/Loader/EzLoader';
import FooterForUpdate from '@/views/platform/venue/xero/settings/FooterForUpdate';
import UpdateModal from '@/views/platform/venue/xero/settings/UpdateModal';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import { mapGetters } from 'vuex';
import { LOADING_KEY, SELECT_DESELECT_ALL } from '@/util/constants';
import { debounce } from '@/util/utils';
import EzFilterList from '@/components/ui/FilterList';
import EzInput from '@/components/ui/Input/EzInput.vue';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';

export default {
  mixins: [xeroGuard],
  components: {
    VSelectSearch,
    EzTable,
    VVenueEntityInfo,
    EmptyState,
    VDataWithInfo,
    EzLoader,
    FooterForUpdate,
    UpdateModal,
    EzCheckbox,
    EzFilterList,
    EzInput,
    EzDropdown,
  },
  props: {
    venueId: {
      type: Number,
    },
  },
  data() {
    return {
      isLoaderShown: false,
      buttonDisabled: true,
      contacts: [],
      connectedDistributors: [],
      tableHeaders: {
        distributorName: this.supplierNameCheckbox,
      },
      columns: [],
      trackingCategories: [],
      taxes: [],
      accountsData: {},
      selectedDistributors: [],
      updateData: {},
      selected: false,
      listOfDistributorIds: [],
      filters: {
        term: null,
        operative: true,
      },
      operativeFilterOptions: [
        {
          id: 1,
          name: 'Operative',
        },
        {
          id: 2,
          name: 'All',
        },
      ],
      operativeId: 1,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    isLoadingXero() {
      return this.isSomeLoading([LOADING_KEY.VENUE_UPDATE_XERO_SUPPLIERS]);
    },
    columnsNested() {
      return ['distributorName', ...this.columns, 'account', 'inventory', 'taxRate', 'xeroContact'];
    },
    entity() {
      return this.$t('global.distributors');
    },
    accounts() {
      return this.accountsData?.accounts?.length;
    },
    checkedDistributors() {
      return this.selectedDistributors.filter(sv => sv.checked);
    },
    uncheckedDistributors() {
      return this.selectedDistributors.filter(sv => !sv.checked);
    },
    checkedDistributorsCount() {
      return this.selected
        ? this.connectedDistributors.length - this.listOfDistributorIds.length
        : this.listOfDistributorIds.length;
    },
    indeterminate() {
      return (
        this.checkedDistributorsCount > 0 &&
        this.checkedDistributorsCount !== this.connectedDistributors.length
      );
    },
    areAllSelected() {
      return this.checkedDistributorsCount === this.connectedDistributors.length;
    },
    distributorsToUpdate() {
      const distributors =
        this.selected && this.filters.term === null
          ? this.uncheckedDistributors
          : this.checkedDistributors;
      return distributors.map(vn => ({
        distributorId: vn.distributorId,
        contactId: vn.xeroContact?.xeroId,
      }));
    },
    isLoading() {
      return this.isLoaderShown || this.isLoadingXero;
    },
  },
  methods: {
    openUpdateModal() {
      this.$refs.updateModal.open();
    },
    xeroSelected(contact, venue) {
      let foundedContact;
      if (contact.selected === null) {
        foundedContact = this.connectedDistributors.find(item => venue.id === item.entity.id);
        foundedContact.xeroContact = null;
      } else {
        foundedContact = this.connectedDistributors.find(item => venue.id === item.entity.id);
        foundedContact.xeroContact = contact;
      }
      this.saveChanges(foundedContact);
    },
    async saveChanges(item) {
      const data = [
        {
          contactId: item.xeroContact?.xeroId || null,
          distributorId: item.entity?.id,
        },
      ];
      try {
        await venueXeroAddConnections(this.venueId, { connections: data });
        await this.getSupplierListAndContacts();
        flash.success({
          title: this.$t('xero.settings.distributors.flashMessages.success.title'),
        });
      } catch (e) {
        flash.error({
          title: this.$t('xero.settings.distributors.flashMessages.error.title'),
        });
      }
    },
    isChecked(row) {
      return this.selectedDistributors?.find(sp => sp.distributorId === row.entity.id)?.checked;
    },
    getTrackingValue(item, col) {
      return item?.xeroConfig?.tracking?.[col] || '-';
    },
    supplierNameCheckbox(h) {
      return h('span', { class: 'u-flex-center select-all-checkbox' }, [
        h(
          'ez-checkbox',
          {
            class: 'cursor-pointer',
            props: {
              indeterminate: this.indeterminate,
              label: `${this.$t('global.distributor')}`,
              checked: this.areAllSelected,
            },
            on: {
              change: ev => {
                this.selectDeselectAll(ev);
              },
            },
          },
          [],
        ),
      ]);
    },
    selectDeselectAll(ev) {
      this.selected = ev;
      this.listOfDistributorIds = [];
      this.selectedDistributors = this.selectedDistributors.map(cv => ({ ...cv, checked: ev }));
    },
    onCheckboxChange(pr, checked) {
      if (this.selected !== checked)
        this.listOfDistributorIds.push({ distributorId: pr.entity.id });
      else
        this.listOfDistributorIds = this.listOfDistributorIds.filter(
          item => item.distributorId !== pr.entity.id,
        );
      this.selectedDistributors.find(pro => pro.distributorId === pr.entity.id).checked = checked;
    },
    async getTrackingCategories() {
      const {
        data: { data },
      } = await venueFetchTrackingCategories(this.venueId);
      this.trackingCategories = data;
    },
    async getSupplierListAndContacts() {
      this.isLoaderShown = true;
      const { data: connectedDistributors } = await venueGetConnectedDistributors(
        this.venueId,
        this.filters.term,
        this.filters.operative,
      );
      const { data: contacts } = await venueGetContacts(this.venueId);

      this.connectedDistributors = connectedDistributors.data;
      this.contacts = contacts.data;

      this.columns = connectedDistributors.meta.columns;
      this.connectedDistributors.forEach(item => {
        if (this.selectedDistributors.findIndex(x => x.distributorId === item.entity.id) === -1) {
          this.selectedDistributors.push({
            ...item,
            distributorId: item.entity.id,
            checked: this.selected,
          });
        }
      });

      this.isLoaderShown = false;
      this.contacts = [
        ...this.contacts,
        ...this.connectedDistributors
          .filter(pair => pair.xeroContact !== null)
          .map(item => item.xeroContact),
      ];
      this.contacts = this.contacts.reduce((unique, o) => {
        if (!unique.some(obj => obj.xeroId === o.xeroId)) {
          unique.push(o);
        }
        return unique;
      }, []);
    },
    async getAccounts() {
      const {
        data: { data: accounts },
      } = await venueGetXeroAccounts(this.venueId, true);
      this.accountsData = accounts;
      this.accountsData = {
        accounts,
      };

      const {
        data: { data: taxes },
      } = await venueGetXeroTaxesSetup(this.venueId, { withDefault: true });
      this.taxes = taxes;
    },
    async updateDistributors(ev) {
      this.config = ev;
      this.updateData = {
        ...this.updateData,
        config: this.config,
        state:
          this.selected && this.filters.term === null
            ? SELECT_DESELECT_ALL.selectedAll
            : SELECT_DESELECT_ALL.deselectedAll,
      };
      await venueXeroConnections(this.venueId, this.updateData);
      await this.getSupplierListAndContacts();
      this.$refs.updateModal.close();
    },
    async resetFilters() {
      Object.keys(this.filters).forEach(key => {
        this.filters[key] = null;
      });
      this.selectDeselectAll(false);
      this.selectedDistributors = [];
      this.operativeId = null;
      await this.getSupplierListAndContacts();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
      }
      this.selectDeselectAll(false);
      this.selectedDistributors = [];
      await this.getSupplierListAndContacts();
    }, 300),
    setOperativeFilter(item) {
      this.operativeId = item.id;
      if (item.id === 1) this.filters.operative = true;
      else this.filters.operative = false;
    },
  },
  created() {
    this.getSupplierListAndContacts();
    this.getTrackingCategories();
    this.getAccounts();
  },
  watch: {
    distributorsToUpdate() {
      this.updateData = {
        ...this.updateData,
        items: this.distributorsToUpdate,
      };
    },
    checkedDistributors(val) {
      window.Intercom('update', {
        hide_default_launcher: !!val.length,
      });
    },
  },
};
</script>
<template>
  <div>
    <div class="header mb-24">
      <span class="header__info-text">
        To edit tracking categories and accounts, select the
        {{ $t('global.distributors') | lowercase }} you want to update and click on the “Update
        {{ $t('global.distributors') }}” button at the bottom.
      </span>
    </div>
    <ez-filter-list
      :filters="filters"
      @filterUpdated="updateFilters"
      @resetFilter="resetFilters"
      class="mb-24"
    >
      <ez-input
        formKey="filters"
        name="search"
        class="search"
        :placeholder="`Search for a ${$t('global.distributor')}`"
      >
        <template #prefix>
          <font-awesome-icon icon="search" />
        </template>
      </ez-input>
      <ez-dropdown
        ref="select"
        class="operative-filter ml-12"
        label="Operative"
        placeholder="Filter Suppliers"
        :data="operativeFilterOptions"
        name="operative"
        is-full-width
        @change="setOperativeFilter"
        :selected="operativeId"
      />
    </ez-filter-list>
    <div>
      <ez-table
        v-if="connectedDistributors.length"
        class="sync-table"
        :disable-hover="true"
        :data="connectedDistributors"
        :columns="columnsNested"
        :headers="tableHeaders"
        :columnProps="{
          supplierName: { class: 'supplier-cell' },
          xeroContact: { class: 'xxl-cell' },
        }"
      >
        <template #cell-distributorName="{ row }">
          <div class="supplier-name">
            <ez-checkbox
              class="cursor-pointer mr-8"
              :key="row.id"
              @change="onCheckboxChange(row, $event)"
              :checked="isChecked(row)"
            />
            <v-venue-entity-info
              class="cursor-pointer"
              :venue="row.entity"
              @click.native="onCheckboxChange(row, !isChecked(row))"
            />
          </div>
        </template>
        <template v-for="cl in columns" #[`cell-${cl}`]="{ row }">
          {{ getTrackingValue(row, cl) }}
        </template>
        <template #cell-account="{ row: { xeroAccount } }">
          <v-data-with-info
            v-if="xeroAccount"
            :show-underline="xeroAccount.default"
            :info="xeroAccount.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ xeroAccount.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>
        <template
          #cell-inventory="{
            row: {
              xeroAccounts: { inventory },
            },
          }"
        >
          <v-data-with-info
            v-if="inventory"
            :show-underline="inventory.default"
            :info="inventory.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ inventory.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>
        <template #cell-taxRate="{ row: { xeroTax } }">
          <v-data-with-info
            v-if="xeroTax"
            :show-underline="xeroTax.default"
            :info="xeroTax.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ xeroTax.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>
        <template #cell-xeroContact="{ row }">
          <v-select-search
            :data="contacts"
            :search="`/venues/${venueId}/xero/contacts`"
            value-property="xeroId"
            placeholder="Select Xero Contact"
            :selected="row.xeroContact && row.xeroContact.xeroId"
            @selected="obj => xeroSelected(obj, row.entity)"
          />
        </template>
      </ez-table>
      <template v-if="!connectedDistributors.length">
        <empty-state>
          <template #badge>
            <img src="@/assets/no-venue-empty-state.svg" width="160" alt=""
          /></template>
          <template #title>{{ $t('xero.settings.distributors.emptyState.title') }}</template>
        </empty-state>
      </template>
      <update-modal
        v-if="accounts"
        ref="updateModal"
        :entity="entity"
        :categories="trackingCategories"
        :acc-data="accountsData"
        @update="updateDistributors"
        :taxes="taxes"
        :show-tax-rate="true"
      />
      <footer-for-update
        v-if="checkedDistributorsCount"
        :items-count="checkedDistributorsCount"
        :entity="entity"
        @footerAction="openUpdateModal"
      />
      <ez-loader :show="isLoading">Loading...</ez-loader>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.sync-table tbody tr td {
  overflow: visible;
}
.supplier-name {
  display: flex;
  align-items: center;
}
.supplier-cell {
  width: 500px;
}
.select-all-checkbox {
  .input-group {
    @include font-size(12px);
    :deep() .check-indicator {
      margin-right: 8px;
    }
  }
}
.header {
  width: 400px;
  &__info-text {
    @include font-size(14px, 20px);
    font-weight: 500;
    color: $color-gray-6C;
  }
}

:deep() .ez-filter-list__items .search,
:deep() .operative-filter.input-group {
  width: 260px;
}
</style>
