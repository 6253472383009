<template>
  <div
    :class="['input-group', { disabled }, { error: serverError || clientError || hasUserError }]"
    v-tooltip="tooltipError">
    <label v-if="label" class="input-group__label" :for="id">{{ label }}</label>
    <div :class="{
      'input-group__input': true,
      'input-group__input--has-prefix': hasPrefixSlot,
      'input-group__input--has-suffix': hasSuffixSlot,
      }">
      <div class="input-group__prefix" v-if="hasPrefixSlot">
        <!-- @slot Use this slot to add a prefix to the input. -->
        <slot name="prefix"></slot>
      </div>
      <input
        :ref="id"
        :id="id"
        :type="type"
        :name="name"
        :min="min"
        :max="max"
        :placeholder="placeholder"
        @input="onChange"
        v-model="val"
        :disabled="disabled"
        :required="required"
        :readonly="readonly"
        :autocomplete="autocomplete"
        :step="step"
        v-validate="validators"
        :title="tooltip || (disabled && disabledTooltip)"
        data-vv-delay="1000"
        :data-cy="dataCy"
      >
      <div class="input-group__suffix" v-if="hasSuffixSlot">
        <!-- @slot Use this slot to add a suffix to the input. -->
        <slot name="suffix"></slot>
      </div>
    </div>
    <div
      v-if="(hasClientError && !isTooltipError) || (hasUserError && !isTooltipError)"
      class="input-group__error">{{ errorMsg || clientError }}
    </div>
    <div
      v-if="hasServerErrorMessage && !isTooltipError"
      class="input-group__error">{{ serverError.message }}
    </div>
    <div v-else-if="$slots.help" class="input-group__help">
      <!-- @slot Use this slot to show field help -->
      <slot name="help"></slot>
    </div>
  </div>
</template>

<script>
import uuid from 'uuid/v4';
import { mapMutations } from 'vuex';
import dayjs from 'dayjs';
import { formatErrorDate, formatErrorTime } from '@/util/utils';

/**
 * Form input used around the website. supports prefix and/or suffix.
 * Also able to get its error messages from the vuex state based on the `formKey` and `name` props.
 * @version 1.0.0
 */
export default {
  /**
   * Name is explicitly needed here because it will be used to
   * dynamically restart inputs inside ezForm
   * @see src/components/ui/Form/EzForm.vue
   */
  name: 'ezinput',
  props: {
    /**
     * Key from the parent form
     */
    formKey: {
      required: true,
      type: String,
    },
    /**
     * Input name
     */
    name: {
      required: true,
      type: String,
    },
    /**
     * Input value
     */
    value: {
      required: false,
      default: '',
      type: [Number, String],
    },
    /**
     * Set the step number for number fields.
     * Without this floats won't be allowed.
     */
    step: {
      required: false,
      type: String,
    },
    /**
     * Label that is above the input.
     */
    label: {
      required: false,
      type: String,
    },
    /**
     * Type of input field used.
     * `text, email, number, url, file, search, date, password`
     */
    type: {
      required: false,
      type: String,
      default: 'text',
      validator: value => ['text', 'email', 'number', 'url', 'file', 'search', 'date', 'password', 'hidden', 'time'].indexOf(value) !== -1,
    },
    /**
     * Placeholder text inside the input.
     */
    placeholder: {
      required: false,
      type: String,
    },
    /**
     * Tooltip text, describes input's purpose or state on hover
     */
    tooltip: {
      required: false,
      type: String,
    },
    /**
     * If the input is disable or not.
     */
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabledTooltip: {
      required: false,
      type: String,
      default: 'You might not have permission to edit this field.',
    },
    /**
     * If the input is readonly or not.
     */
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * If the input is required or not.
     */
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    min: {
      required: false,
    },
    max: {
      required: false,
    },
    /**
     *
     */
    validators: {
      required: false,
      type: [Object, String, Function],
    },
    autocomplete: {
      type: String,
    },
    errorMsg: {
      type: String,
      default: '',
    },
    /**
     * If the error should be shown in a tooltip.
     * There are rare cases where the error can't fit, therefore use a tooltip.
     */
    isTooltipError: {
      type: Boolean,
      default: false,
    },
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: null,
      val: '',
    };
  },
  computed: {
    serverError() {
      return this.$store.getters['errors/getError'](this.formKey, this.name);
    },
    clientError() {
      const veeValidateErrorMsg = this.errors.first(this.name);
      const dateErrorMsg = this.formatErrorMsgDate(veeValidateErrorMsg);
      const timeErrorMsg = this.formatErrorMsgTime(veeValidateErrorMsg);

      return dateErrorMsg || timeErrorMsg || veeValidateErrorMsg;
    },
    hasPrefixSlot() {
      return !!this.$slots.prefix;
    },
    hasSuffixSlot() {
      return !!this.$slots.suffix;
    },
    hasServerErrorMessage() {
      return this.serverError
        && typeof this.serverError === 'object'
        && Object.prototype.hasOwnProperty.call(this.serverError, 'message');
    },
    hasUserError() {
      return !!this.errorMsg;
    },
    hasClientError() {
      return !!this.clientError;
    },
    validationsState() {
      return this.fields[this.name];
    },
    tooltipError() {
      if (!this.isTooltipError) return {};
      const errorMsg = [];

      if (this.hasClientError) errorMsg.push(`<div>${this.clientError}</div>`);
      if (this.hasUserError) errorMsg.push(`<div>${this.errorMsg}</div>`);
      if (this.hasServerErrorMessage) errorMsg.push(`<div>${this.serverError.message}</div>`);

      return {
        content: errorMsg.join('\n'),
        classes: ['tooltip-general'],
        show: true,
        autoHide: false,
      };
    },
  },
  methods: {
    ...mapMutations('errors', [
      'CLEAR_ERROR',
    ]),
    focus() {
      this.$refs[this.id].focus();
    },
    formatErrorMsgDate(errorMsg) {
      if (!errorMsg) {
        return null;
      }

      const tempArr = errorMsg.split(' ');
      const dateIndex = tempArr.length - 1;
      const dateStr = tempArr[dateIndex];

      const date = dayjs(dateStr);
      if (!date.isValid()) {
        return null;
      }

      tempArr[dateIndex] = formatErrorDate(date);

      return tempArr.join(' ');
    },
    formatErrorMsgTime(errorMsg) {
      if (!errorMsg) {
        return null;
      }

      const tempArr = errorMsg.split(' ');
      const timeIndex = tempArr.length - 1;
      const timeStr = tempArr[timeIndex];

      let [hours, minutes] = timeStr.split(':');
      hours = Number(hours);
      minutes = Number(minutes);

      if (!Number.isFinite(hours) || !Number.isFinite(minutes)) {
        return null;
      }

      const dateTime = dayjs()
        .set('hours', hours)
        .set('minutes', minutes);

      tempArr[timeIndex] = formatErrorTime(dateTime);

      return tempArr.join(' ');
    },
    onChange() {
      if (this.hasServerErrorMessage) {
        this.CLEAR_ERROR({
          formKey: this.formKey,
          field: this.name,
        });
      }

      /**
       * When input value is changed.
       * @event onChange
       * @type String
       */
      this.$emit('onChange', this.val);
    },
    reset() {
      this.val = '';
    },
    async clearErrors() {
      await this.$nextTick();
      this.errors.clear();
      await this.$validator.reset();
      this.CLEAR_ERROR({ formKey: this.formKey, field: this.name });
    },
  },
  watch: {
    validationsState: {
      deep: true,
      handler(newValue, oldValue) {
        /**
         * When validation state is changed.
         * @event onChange
         * @type Object
         */
        return this.$emit('validationChange', newValue, oldValue);
      },
    },
    value: {
      immediate: true,
      handler(newVal, oldVal) {
        this.val = newVal;

        if (newVal && oldVal && newVal !== oldVal) {
          this.onChange();
        }
      },
    },
  },
  mounted() {
    this.id = uuid();
  },
};
</script>


<style lang="scss" scoped>
  .input-group {
    text-align: left;

    &__label {
      display: block;
      margin-bottom: .375rem;
      color: $label-color;
      @include font-size($label-font-size, $label-line-height);
      font-weight: 500;
      text-transform: capitalize;
    }

    &__input {
      display: flex;
      position: relative;
      height: $input-height;
      border-radius: $input-border-radius;
      background-color: #FFFFFF;
      @include font-size(14px);

      input {
        @extend %input-reset;
        width: 100%;
        padding: 0 $input-padding-x;
        border-radius: $input-border-radius;
        border: 1px solid $input-border-color;
        color: $input-color;
        font-weight: 400;
        outline: none;

        &::placeholder {
          color: $input-placeholder-color;
        }

        &:not(:read-only):focus {
          border: $input-focus-border;
        }

        &:not(:read-only):focus, &::placeholder {
          margin-left: -1px;
        }

        &:read-only, &:disabled {
          background-color: $input-disabled-bg-color;
          border: 0;
          &::placeholder {
            color: $input-disabled-color;
          }
        }

        &:disabled {
          cursor: not-allowed;
        }
      }

      &--has-prefix {
        &:focus-within .input-group__prefix {
          border-color: $input-border-color-active;
          color: $input-border-color-active;
        }

        input {
          padding-left: calc(#{$prefix-left-adjustment} + 18px);
        }
      }

      &--has-suffix {
        &:focus-within .input-group__suffix {
          border-color: $input-border-color-active;
          color: $input-border-color-active;
        }

        input {
          padding-right: calc(#{$input-height} + 8px);
        }
      }
    }

    &__prefix,
    &__suffix {
      position: absolute;
      color: $label-color;
      background: none;
      text-align: center;
      font-weight: bold;
      line-height: calc(#{$input-height} - 2px);
      z-index: 5;
    }

    &__prefix {
      top: 1px;
      left: $prefix-left-adjustment;
    }

    &__suffix {
      min-width: $input-height;
      top: 1px;
      right: 1px;
    }

    &__error {
      margin-top: 0.5rem;
      color: $input-border-error-color;
      @include font-size(12px);
    }

    &__help {
      @include font-size(12px);
      margin-top: 0.5rem;
      color: $label-color;
    }

    &.disabled {
      .input-group {
        &__input {
          background-color: $input-disabled-bg-color;

          input {
            color: $input-disabled-color
          }

          input::placeholder {
            color: transparent;
          }
        }

        &__prefix {
          color: $input-disabled-color;
        }
      }
    }

    &.error {
      .input-group {
        &__input {
          &, input { border-color: $input-border-error-color; }
        }

        &__label {
          color: $input-border-error-color;
        }
      }
    }
  }
</style>
