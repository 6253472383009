<script>
/**
 * QuickBooks Taxes
 * @version 1.0.0
 * @since
 */

import xeroGuard from '@/views/platform/venue/xero/settings/xeroGuard';
import { mapGetters } from 'vuex';
import {
  venueGetTaxRateConfig,
  venueUpdateTaxRateConfig,
  venueGetXeroTaxesSetup,
} from '@/api/endpoints/xero';
import EzButton from '@/components/ui/Button/EzButton.vue';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import EzInput from '@/components/ui/Input/EzInput.vue';
import { LOADING_KEY } from '@/util/constants';
import EzLoader from '@/components/ui/Loader/EzLoader.vue';
import flash from '@/components/ui/FlashMessage';

export default {
  mixins: [xeroGuard],
  props: {
    venueId: {
      type: Number,
    },
  },
  components: {
    EzButton,
    VSelectSearch,
    EzInput,
    EzLoader,
  },
  data() {
    return {
      taxRates: [],
      selectedTaxRates: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    addTaxRateMappingDisabled() {
      return this.taxRates.length === this.selectedTaxRates.length;
    },
    saveTaxRateMappingDisabled() {
      return (
        this.selectedTaxRates.length > 0 &&
        this.selectedTaxRates.filter(item => item.rate || item.rate === 0).length === 0
      );
    },
    isSaving() {
      return this.getLoading(LOADING_KEY.VENUE_UPDATE_QUICK_BOOKS_PRODUCT_GROUPS);
    },
  },
  methods: {
    findSelected(id) {
      return this.taxRates.find(w => w.taxType === id);
    },
    transformer(data) {
      return data.filter(({ taxType }) => !this.selectedTaxRates.map(w => w.tax).includes(taxType));
    },
    selectTaxRate(selected, idx) {
      let updated = { tax: selected.taxType, rate: 0 };

      if (!selected.reset) {
        updated = {
          ...this.selectedTaxRates[idx],
          tax: selected.taxType,
          rate: this.selectedTaxRates[idx].rate,
        };
      }

      this.selectedTaxRates.splice(idx, 1, updated);
    },
    addTaxRateMapping() {
      const tax = this.taxRates.filter(
        ({ taxType }) => !this.selectedTaxRates.map(w => w.tax).includes(taxType),
      );
      this.selectedTaxRates = [
        ...this.selectedTaxRates,
        {
          tax: tax[0].taxType,
          rate: 0,
        },
      ];
    },
    updateTaxRate(id, val) {
      const idx = this.selectedTaxRates.findIndex(w => w.tax === id);
      this.selectedTaxRates[idx].rate = val;
    },
    async updateTaxRates() {
      try {
        await venueUpdateTaxRateConfig({
          id: this.venueId,
          body: {
            taxRateConfig: this.selectedTaxRates.map(item => ({ tax: item.tax, rate: +item.rate })),
          },
        });
        flash.success({
          title: 'Tax Rate mapping successfully updated!',
        });
      } catch (e) {
        console.error(e);
      }
    },
    removeTaxRate(id) {
      const idx = this.selectedTaxRates.findIndex(w => w.tax === id);
      this.selectedTaxRates.splice(idx, 1);
    },
  },
  async created() {
    this.isLoading = true;
    const { data } = await venueGetTaxRateConfig({ id: this.venueId });
    this.selectedTaxRates = data.data;
    const {
      data: { data: taxes },
    } = await venueGetXeroTaxesSetup(this.venueId);
    this.taxRates = taxes;
    this.isLoading = false;
  },
};
</script>
<template>
  <div class="tiers">
    <div class="tiers__item">
      <h4>Tax Rate Mapping</h4>
      <div class="warehouses">
        <div v-for="(tax, idx) in selectedTaxRates" :key="idx" class="warehouses__warehouse">
          <ez-button
            type="link"
            class="warehouses__warehouse__remove"
            @click="removeTaxRate(tax.tax)"
          >
            <font-awesome-icon icon="times" /> Remove
          </ez-button>
          <v-select-search
            v-if="taxRates.length"
            label="Tax"
            placeholder="Select Tax"
            class="warehouses__warehouse__select"
            :data="taxRates"
            value-property="taxType"
            :selected="findSelected(tax.tax)"
            :transformer="transformer"
            @selected="obj => selectTaxRate(obj, idx)"
            is-full-width
          />
          <ez-input
            placeholder="Tax Rate"
            form-key="tax-mapping"
            type="number"
            name="tax"
            step="any"
            label="Tax Rate"
            :value="tax.rate"
            @onChange="updateTaxRate(tax.tax, $event)"
            min="0"
            class="mt-12"
          >
            <template #suffix>%</template>
          </ez-input>
        </div>
        <ez-button
          type="secondary"
          class="add-par-level my-24"
          :disabled="addTaxRateMappingDisabled"
          @click="addTaxRateMapping"
          isFullWidth
        >
          <font-awesome-icon icon="plus" />
          Add Tax Rate Mapping
        </ez-button>
      </div>
      <ez-button
        @click="updateTaxRates"
        :isLoading="isSaving"
        :disabled="saveTaxRateMappingDisabled"
      >
        Save Changes
      </ez-button>
    </div>
    <ez-loader :show="isLoading" />
  </div>
</template>
<style lang="scss" scoped>
.tiers {
  max-width: 400px;
  &__item {
    &:not(:first-child) {
      @include separator('top', 2rem);
    }
    h4 {
      @include font-size(14px, 16px);
      font-weight: 500;
      margin: 0 0 0.5rem;
    }

    p {
      @include font-size(14px, 22px);
      margin: 0 0 1rem 0;
      color: $color-gray-6C;
    }
  }
}

.warehouses {
  &__warehouse {
    position: relative;
    padding-top: 24px;

    &:not(:first-child) {
      margin-top: 24px;
      border-top: 1px dashed #dee1e4;
    }

    &__existing {
      &__label {
        display: block;
        @include font-size(12px, 18px);
        font-weight: 600;
        color: $color-gray-6C;
      }

      &__name {
        display: block;
        @include font-size(14px, 20px);
        font-weight: 500;
      }
    }

    &__select {
      :deep() .select-search {
        &__trigger span {
          @include font-size(14px, 20px);

          &:not(.select-search__value) {
            color: $color-gray-6C;
          }
        }

        &__search-wrapper {
          display: none;
        }

        &__item {
          @include font-size(14px, 20px);
        }
      }
    }

    &__par-level {
      :deep() .mask-input__input {
        font-weight: normal;
      }
    }

    &__remove {
      @include font-size(12px, 18px);
      position: absolute;
      top: 24px;
      right: 0;
      height: auto;
    }
  }

  .add-par-level {
    &:disabled {
      background-color: $color-gray-F5;
      color: transparentize($color-gray-6C, 0.6);
    }
  }
}
</style>
