<script>
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';

/**
 * Xero Account Settings Form
 * @version 1.0.0
 * @since
 */
export default {
  components: {
    VSelectSearch,
    EzDropdown,
  },
  props: {
    accounts: {
      type: Array,
      required: true,
      default: () => [],
    },
    orderExportModes: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedExportModeId: {
      type: String,
      required: false,
    },
    taxes: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedAccount: {
      type: String,
      required: false,
    },
    selectedInventoryAccount: {
      type: String,
      required: false,
    },
    selectedTax: {
      type: String,
      required: false,
    },
    selectedDeliveryFeeAccount: {
      type: String,
      required: false,
    },
    selectedOrderDiscountAccount: {
      type: String,
      required: false,
    },
    selectedTaxAdjustment: {
      type: String,
      required: false,
    },
    selectedAmountAdjustment: {
      type: String,
      required: false,
    },
  },
  methods: {
    changeSelectedAccount(item) {
      this.$emit('changeAccount', item);
    },
    changeSelectedInventoryAccount(item) {
      this.$emit('changeInventoryAccount', item);
    },
    changeSelectedTax(item) {
      this.$emit('changeTax', item);
    },
    changeExportMode(item) {
      this.$emit('changeExportMode', item);
    },
    changeSelectedDeliveryFee(item) {
      this.$emit('changeDeliveryFee', item);
    },
    changeSelectedDiscount(item) {
      this.$emit('changeDiscount', item);
    },
    changeSelectedTaxAdjustment(item) {
      this.$emit('changeTaxAdjustment', item);
    },
    changeSelectedAmountAdjustment(item) {
      this.$emit('changeAmountAdjustment', item);
    },
  },
};
</script>
<template>
  <div>
    <v-select-search
      class="account-dropdown width-100"
      align-left
      is-full-width
      name="account"
      label="Account"
      :data="accounts"
      value-property="code"
      placeholder="Select Account"
      searchPlaceholder="Search Account"
      :selected="selectedAccount"
      :has-clear="true"
      @selected="changeSelectedAccount"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="account-dropdown width-100 mt-16"
      align-left
      is-full-width
      name="taxRates"
      label="Tax Rate"
      :data="taxes"
      value-property="taxType"
      placeholder="Select Tax Rate"
      searchPlaceholder="Select Tax Rate"
      :selected="selectedTax"
      :has-clear="true"
      @selected="changeSelectedTax"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="mt-16 width-100"
      align-left
      is-full-width
      name="deliveryFeeAccountCode"
      label="Delivery Fee Account"
      :data="accounts"
      value-property="code"
      placeholder="Select Delivery Fee Account"
      searchPlaceholder="Search Delivery Fee Account"
      :selected="selectedDeliveryFeeAccount"
      :has-clear="true"
      @selected="changeSelectedDeliveryFee"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="mt-16"
      align-left
      is-full-width
      name="orderDiscountAccountCode"
      label="Order Discount Account"
      :data="accounts"
      value-property="code"
      placeholder="Select Order Discount Account"
      searchPlaceholder="Search Order Discount Account"
      :selected="selectedOrderDiscountAccount"
      :has-clear="true"
      @selected="changeSelectedDiscount"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="account-dropdown width-100 mt-16"
      align-left
      is-full-width
      name="venueInventoryAccount"
      label="Inventory Account"
      :data="accounts"
      value-property="code"
      placeholder="Select Inventory Account"
      searchPlaceholder="Search Inventory Account"
      :selected="selectedInventoryAccount"
      :has-clear="true"
      @selected="changeSelectedInventoryAccount"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="mt-16 width-100"
      align-left
      is-full-width
      name="taxAdjustmentAccountCode"
      label="Tax Adjustment Account"
      :data="accounts"
      value-property="code"
      placeholder="Select Tax Adjustment Account"
      searchPlaceholder="Search Tax Adjustment Account"
      :selected="selectedTaxAdjustment"
      :has-clear="true"
      @selected="changeSelectedTaxAdjustment"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <v-select-search
      class="mt-16 width-100"
      align-left
      is-full-width
      name="amountAdjustmentAccountCode"
      label="Price Adjustment Account"
      :data="accounts"
      value-property="code"
      placeholder="Select Price Adjustment Account"
      searchPlaceholder="Search Price Adjustment Account"
      :selected="selectedAmountAdjustment"
      :has-clear="true"
      @selected="changeSelectedAmountAdjustment"
    >
      <template #result="{ result }">
        <span>
          {{ result.name }}
        </span>
      </template>
    </v-select-search>
    <ez-dropdown
      v-if="orderExportModes.length"
      name="accountingInvoicePush"
      label="Order Export Mode"
      placeholder="Select Order Export Mode"
      class="mt-12"
      :data="orderExportModes"
      :selected="selectedExportModeId"
      @change="changeExportMode"
      is-full-width
    />
  </div>
</template>
<style lang="scss" scoped>
:deep() .select-search__trigger {
  span {
    color: $color-gray-6C;
  }
  svg {
    color: $color-gray-6C;
  }
  span.select-search__value {
    color: $color-gray-25;
  }
}

:deep() .select-search__list li.select-search__item {
  padding: 0.625rem 1rem;
}

:deep() .select-search__list li.select-search__item.clear {
  padding: 0rem 1rem;
}
</style>
