import { render, staticRenderFns } from "./EzInput.vue?vue&type=template&id=2a898128&scoped=true&"
import script from "./EzInput.vue?vue&type=script&lang=js&"
export * from "./EzInput.vue?vue&type=script&lang=js&"
import style0 from "./EzInput.vue?vue&type=style&index=0&id=2a898128&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a898128",
  null
  
)

export default component.exports