<script>
/**
 * Xero Product Groups
 * @version 1.0.0
 * @since
 */

import xeroGuard from '@/views/platform/venue/xero/settings/xeroGuard';
import {
  venueGetProductGroups,
  venueUpdateXeroProductGroups,
  venueFetchTrackingCategories,
  venueGetXeroAccounts,
  venueGetXeroTaxesSetup,
} from '@/api/endpoints/xero';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { mapGetters } from 'vuex';
import { LOADING_KEY, SELECT_DESELECT_ALL } from '@/util/constants';
import EzTable from '@/components/ui/Table/EzTable';
import EmptyState from '@/views/common/empty-state/EmptyState';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import FooterForUpdate from '@/views/platform/venue/xero/settings/FooterForUpdate';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import UpdateModal from '@/views/platform/venue/xero/settings/UpdateModal';
import { debounce } from '@/util/utils';
import EzFilterList from '@/components/ui/FilterList';
import EzInput from '@/components/ui/Input/EzInput.vue';

export default {
  mixins: [xeroGuard],
  components: {
    UpdateModal,
    VDataWithInfo,
    EzLoadMore,
    EzSpinner,
    FooterForUpdate,
    EzCheckbox,
    EmptyState,
    EzTable,
    EzLoader,
    EzFilterList,
    EzInput,
  },
  props: {
    venueId: {
      type: Number,
    },
  },
  data() {
    return {
      meta: {},
      productGroups: [],
      dynamicColumns: [],
      selected: false,
      listOfProductGroupIds: [],
      trackingCategories: [],
      accountsData: {},
      taxes: [],
      filters: {
        term: null,
      },
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.VENUE_GET_PRODUCT_GROUPS]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.VENUE_GET_MORE_PRODUCT_GROUPS);
    },
    allColumns() {
      return ['name', ...this.dynamicColumns, 'sales', 'inventory', 'taxRate'];
    },
    entity() {
      return this.$t('global.productGroups');
    },
    /**
     * Selected indicates if selected all checkbox is checked.
     * In case when this is true, listOfProductGroupIds contains objects that shouldn't be  updated,
     * and are unselected in this case, therefore, they are subtracted from the total count.
     * On the other hand, when select all checkbox is unchecked,
     * listOfProductGroupIds contains objects that should be updated, and they are the only ones that count.
     */
    bulkActionProductGroupsCount() {
      return this.selected && this.filters.term === null
        ? this.meta.totalCount - this.listOfProductGroupIds.length
        : this.listOfProductGroupIds.length;
    },
    areAllSelected() {
      return this.bulkActionProductGroupsCount === this.meta.totalCount;
    },
  },
  methods: {
    nameCheckbox(h) {
      return h('span', { class: 'u-flex-center' }, [
        h(
          'ez-checkbox',
          {
            class: 'mr-8',
            props: {
              label: 'Product Group',
              indeterminate: this.bulkActionProductGroupsCount > 0 && !this.areAllSelected,
              checked: this.areAllSelected,
            },
            on: {
              change: event => {
                this.selectDeselectAll(event);
              },
            },
          },
          [],
        ),
      ]);
    },
    async selectDeselectAll(selected) {
      this.selected = selected;
      this.listOfProductGroupIds = [];
      if (this.selected && this.filters.term !== null) {
        this.productGroups.forEach(item => this.listOfProductGroupIds.push({ id: item.id }));
      }
    },
    /**
     * Depending on Select all checkbox status, listOfProductGroupIds stores objects' ids
     * that are not selected(in the case of select all checked), or the ones that are selected, when select all checkbox is unchecked.
     */
    onCheckboxChange(productGroup, checked) {
      if (this.filters.term === null) {
        if (this.selected !== checked) this.listOfProductGroupIds.push({ id: productGroup.id });
        else
          this.listOfProductGroupIds = this.listOfProductGroupIds.filter(
            item => item.id !== productGroup.id,
          );
      } else if (checked) {
        this.listOfProductGroupIds.push({ id: productGroup.id });
      } else {
        this.listOfProductGroupIds = this.listOfProductGroupIds.filter(
          item => item.id !== productGroup.id,
        );
      }
    },
    async bulkActionsForProductGroups(config) {
      const items = this.listOfProductGroupIds;
      await venueUpdateXeroProductGroups({
        id: this.venueId,
        body: {
          items,
          config,
          state:
            this.selected && this.filters.term === null
              ? SELECT_DESELECT_ALL.selectedAll
              : SELECT_DESELECT_ALL.deselectedAll,
        },
      });

      await this.refresh();

      this.$refs.updateModal.close();
    },
    isProductGroupSelected(productGroup) {
      return (
        (this.selected &&
          this.filters.term === null &&
          this.listOfProductGroupIds.findIndex(el => el.id === productGroup.id) < 0) ||
        ((!this.selected || this.filters.term) &&
          this.listOfProductGroupIds.findIndex(el => el.id === productGroup.id) >= 0)
      );
    },
    openUpdateModal() {
      this.$refs.updateModal.open();
    },
    setMeta(meta) {
      this.meta = meta;
      this.dynamicColumns = meta.columns;
    },
    getTrackingValue(productGroup, col) {
      return productGroup?.xeroConfig?.tracking?.[col] || '-';
    },
    async refresh() {
      const {
        data: { data, meta },
      } = await venueGetProductGroups({
        id: this.venueId,
        ...this.filters,
      });
      this.productGroups = data;
      this.setMeta(meta);
    },
    async onLoadMore(params = {}) {
      const {
        data: { data, meta },
      } = await venueGetProductGroups({
        id: this.venueId,
        ...this.meta,
        loadingKey: LOADING_KEY.VENUE_GET_MORE_PRODUCT_GROUPS,
        ...params,
        ...this.filters,
      });
      this.productGroups = [...this.productGroups, ...data];
      this.setMeta(meta);
    },
    async resetFilters() {
      Object.keys(this.filters).forEach(key => {
        this.filters[key] = null;
      });
      this.selectDeselectAll(false);
      await this.refresh();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
      }
      this.selectDeselectAll(false);
      await this.refresh();
    }, 300),
  },
  async created() {
    await this.refresh();

    const {
      data: { data: categories },
    } = await venueFetchTrackingCategories(this.venueId);
    this.trackingCategories = categories;

    const {
      data: { data: accounts },
    } = await venueGetXeroAccounts(this.venueId, true);
    this.accountsData = { accounts };

    const {
      data: { data: taxes },
    } = await venueGetXeroTaxesSetup(this.venueId, { withDefault: true });
    this.taxes = taxes;
  },
  watch: {
    bulkActionProductGroupsCount(val) {
      window.Intercom('update', {
        hide_default_launcher: !!val,
      });
    },
  },
};
</script>
<template>
  <div>
    <div class="products__header mb-24">
      <span class="products__info-text">
        {{ productGroups.length ? $t('xero.settings.productGroups.infoText') : '' }}
      </span>
    </div>
    <ez-filter-list
      :filters="filters"
      @filterUpdated="updateFilters"
      @resetFilter="resetFilters"
      class="mb-24"
    >
      <ez-input
        formKey="filters"
        name="search"
        class="search"
        :placeholder="`Search for a ${$t('global.productGroup')}`"
      >
        <template #prefix>
          <font-awesome-icon icon="search" />
        </template>
      </ez-input>
    </ez-filter-list>
    <div>
      <ez-table
        v-if="productGroups.length"
        :data="productGroups"
        :columns="allColumns"
        :headers="{
          name: nameCheckbox,
          sales: () => 'Account',
          inventory: () => 'Inventory Account',
        }"
        :disable-hover="true"
      >
        <template #cell-name="{ row }">
          <div class="u-flex-row u-flex-center">
            <ez-checkbox
              class="cursor-pointer mr-8"
              :key="row.id"
              :checked="isProductGroupSelected(row)"
              @change="onCheckboxChange(row, $event)"
            />
            <span class="product-info">{{ row.name }}</span>
          </div>
        </template>

        <template v-for="cl in dynamicColumns" #[`cell-${cl}`]="{ row }">
          {{ getTrackingValue(row, cl) }}
        </template>

        <template
          #cell-sales="{
            row: {
              xeroAccounts: { sales },
            },
          }"
        >
          <v-data-with-info
            v-if="sales"
            :show-underline="sales.default"
            :info="sales.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ sales.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template
          #cell-inventory="{
            row: {
              xeroAccounts: { inventory },
            },
          }"
        >
          <v-data-with-info
            v-if="inventory"
            :show-underline="inventory.default"
            :info="inventory.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ inventory.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>

        <template #cell-taxRate="{ row: { xeroTax } }">
          <v-data-with-info
            v-if="xeroTax"
            :show-underline="xeroTax.default"
            :info="xeroTax.default ? $t('xero.settings.table.account.defaultInfo') : ''"
          >
            {{ xeroTax.name }}
          </v-data-with-info>
          <span v-else>-</span>
        </template>
      </ez-table>

      <div v-if="isLoadingMore" class="u-text-center mt-12">
        <ez-spinner />
      </div>

      <ez-load-more v-if="!isLoadingMore && meta.nextId" @loadMore="onLoadMore" />
    </div>
    <template v-if="!productGroups.length">
      <empty-state class="empty-state-center">
        <template #badge> <img src="@/assets/no-product-empty-state.svg" alt="" /></template>

        <template #title>{{ $t('xero.settings.productGroups.emptyState.title') }}</template>
        <template #info>
          {{ $t('xero.settings.productGroups.emptyState.info') }}
        </template>
      </empty-state>
    </template>

    <ez-loader :show="isLoading">Loading...</ez-loader>

    <update-modal
      v-if="accountsData && accountsData.accounts"
      ref="updateModal"
      :entity="entity"
      :categories="trackingCategories"
      :acc-data="accountsData"
      @update="bulkActionsForProductGroups"
      :taxes="taxes"
      :show-tax-rate="true"
    />

    <footer-for-update
      v-if="bulkActionProductGroupsCount"
      :entity="entity"
      :items-count="bulkActionProductGroupsCount"
      @footerAction="openUpdateModal"
    />
  </div>
</template>
<style lang="scss" scoped>
.products__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.products__info-text {
  @include font-size(14px, 20px);

  width: 400px;
  font-weight: 500;
  color: $color-gray-6C;
}

.product-info__name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

:deep() .ez-filter-list__items .search {
  width: 260px;
}
</style>
