<script>
/**
 * Xero Settings Page
 * @version 1.0.0
 * @since
 */
import Page from '@/views/admin/SinglePage.vue';
import VStatusBadge from '@/components/v3/elements/VStatusBadge';
import { mapActions, mapGetters } from 'vuex';
import EzButton from '@/components/ui/Button/EzButton';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import { venueDisconnectXero } from '@/api/endpoints/xero';

export default {
  components: {
    EzButton,
    Page,
    VStatusBadge,
    EzConfirmationModal,
  },
  computed: {
    ...mapGetters('entities/users', ['getVenues', 'isVenueXeroConnected', 'isVenueXeroTokenValid']),
    venueId() {
      return this.$route.params.id;
    },
    isXeroConnected() {
      return this.isVenueXeroConnected(this.venueId);
    },
    isXeroTokenValid() {
      return this.isVenueXeroTokenValid(this.venueId);
    },
    canReauthorize() {
      return this.isXeroConnected && !this.isXeroTokenValid;
    },
    organizationName() {
      const venue = this.getVenues.find(v => v.id === +this.venueId);
      return venue?.xero?.tenantName || 'Xero';
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/users', ['initVenue']),
    ...mapActions('xero', ['xeroAuthorize']),
    reauthorizeXero() {
      this.xeroAuthorize({
        route: 'venue-xero-setup',
        state: this.venueId,
      });
    },
    async confirmXeroDisconnect() {
      try {
        await venueDisconnectXero(this.venueId);
        this.$router.push({
          name: 'venue-settings-integrations',
          params: {
            flash: {
              title: 'You have successfully disconnected from Xero.',
            },
          },
        });
      } finally {
        this.$refs.xeroDisconnectModal.close();
      }
    },
  },
  created() {
    this.initVenue();
  },
};
</script>
<template>
  <page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'venue-settings-integrations', params: { id: venueId } }">
        <font-awesome-icon icon="arrow-left" />
        Back to {{ $t('global.venue') }} Settings
      </router-link>
    </template>
    <template #title>
      <div class="u-flex-center title">
        <img src="@/assets/logo-xero.png" width="32" height="32" alt="Xero Logo" />
        <h2 class="m-0 ml-8 xero-title">{{ organizationName }}</h2>
        <v-status-badge type="green" v-if="isXeroConnected">Connected</v-status-badge>
        <v-status-badge type="gray" v-else>Not Connected</v-status-badge>
      </div>
    </template>
    <template #actions>
      <div class="u-flex-center header-actions">
        <ez-button
          v-if="canReauthorize"
          class="mr-12 header-actions__action"
          @click="reauthorizeXero"
        >
          <font-awesome-icon icon="sync-alt" />
          <span>Reauthorize</span>
        </ez-button>
        <ez-button
          v-if="isXeroConnected"
          type="link"
          v-open="'xeroDisconnectModal'"
          class="p-0 header-actions__action"
          aria-label="Disconnect from Xero"
        >
          <img
            src="@/assets/xero-disconnect-btn.svg"
            alt="Disconnect from Xero"
            role="presentation"
          />
        </ez-button>
      </div>
    </template>
    <template #navigation>
      <ul>
        <li>
          <component
            class="navigation-link"
            :is="isXeroConnected ? 'router-link' : 'span'"
            :to="isXeroConnected ? { name: 'venue-xero-account' } : null"
          >
            Account Info
          </component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="isXeroConnected ? 'router-link' : 'span'"
            :to="isXeroConnected ? { name: 'venue-xero-products' } : null"
          >
            Products
          </component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="isXeroConnected ? 'router-link' : 'span'"
            :to="isXeroConnected ? { name: 'venue-xero-venues' } : null"
          >
            {{ $t('global.distributors') }}
          </component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="isXeroConnected ? 'router-link' : 'span'"
            :to="isXeroConnected ? { name: 'venue-xero-product-groups' } : null"
          >
            {{ $t('global.productGroups') }}
          </component>
        </li>
        <li>
          <component
            class="navigation-link"
            :is="isXeroConnected ? 'router-link' : 'span'"
            :to="isXeroConnected ? { name: 'venue-xero-tax' } : null"
          >
            Tax Rates
          </component>
        </li>
      </ul>
    </template>

    <ez-confirmation-modal ref="xeroDisconnectModal" type="red">
      <template #title>{{ $t('xero.integrations.disconnectModalTitle') }}</template>
      <template #content>
        <div
          class="xero-description"
          v-html="$t('xero.integrations.disconnectModalContent', { platform: platformName })"
        />
      </template>
      <template #footer>
        <ez-button v-close="'xeroDisconnectModal'" type="link">
          {{ $t('global.actions.cancel') }}
        </ez-button>
        <ez-button @click="confirmXeroDisconnect" type="red">
          {{ $t('global.actions.disconnect') }}
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </page>
</template>
<style lang="scss" scoped>
.xero-title {
  @include font-size(24px);
  font-weight: 500;
}
.title {
  img {
    border: 1px solid #e1e5ed;
    border-radius: 50%;
  }
}
.header-actions__action {
  height: 43px;
}
</style>
